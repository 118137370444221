/* * * * * * * * * *
+320px :: 20em
+480px :: 30em
+600px :: 37.50em
+768px :: 48em
+992px :: 62em
+1024px :: 64em
+1140px :: 71.250em
+1224px :: 76.5em
+1382px :: 86.375em
* * * * * * * * * */
/* Media Queries*/
/* Phones */
/* Tablets*/
/* Tablets Horizontal*/
/* Desktops, slightly less large devices */
/* Desktops, large devices */
/* Desktops, very large devices */
/* -----------------------------
   FUNCTIONS
   ----------------------------- */
/*
Removes the unit (e.g. px, em, rem) from a value, returning the number only.
@param {Number} $num - Number to strip unit from.
@returns {Number} The same number, sans unit.
*/
/*
Converts one or more pixel values into matching rem values.
@param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.

@param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.

@returns {List} A list of converted values.
*/
/*
Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
@access private
@param {Number} $value - Pixel value to convert.
@param {Number} $base [null] - Base for pixel conversion.
@returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
*/
#grille-solutions-creatives {
  background-image: url("/-/media/Files/IA/solutions-pour-marches-speciaux/fonds/ERI_Fonds_Custom.jpg");
  background-position: bottom right 20%;
  background-size: auto 300px;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  #grille-solutions-creatives {
    padding-bottom: 300px;
  }
}
@media screen and (min-width: 48em) {
  #grille-solutions-creatives {
    background-position: bottom right -195px;
  }
}
@media screen and (min-width: 62.5em) {
  #grille-solutions-creatives {
    background-position: bottom right -400px;
    background-size: auto 450px;
  }
}
@media screen and (min-width: 71.25em) {
  #grille-solutions-creatives {
    background-position: bottom right -300px;
  }
}

.liste-contacts-contact hr {
  display: none;
}
.liste-contacts-contact .details-contact {
  border-top: 2px solid #e0e4e9;
  padding-top: 13px;
  margin-top: 36px;
}